
import { Component, Vue } from 'vue-property-decorator'
import { apiWithdrawLists, apiWithdrawPass, apiWithdrawRefuse } from '@/api/shop'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
  components: {
    LsPagination,
    ExportData,
    LsDialog
  }
})
export default class DesignerWithdraw extends Vue {
  /** S Data **/
  form = {
    title: ''
  }

  // 日期选择器数据
  timeForm = []

  // 设置用户标签弹出框
  dialogVisible = false
  // 选中用户标签
  labelValue = []
  // 用户选择框数据
  userLevelList = {}
  userLabelList = {}
  sourceList = {}
  // 分页查询
  pager: RequestPaging = new RequestPaging()
  // 被选中的用户id
  multipleSelection = []
  /** E Data **/

  /** S Methods **/
  apiWithdrawLists = apiWithdrawLists // 传递给导出组件的api

  // 选中的用户触发事件
  handleSelectionChange (val: any) {
    this.multipleSelection = val
  }

  // 查询按钮
  query () {
    this.pager.page = 1
    this.getUserList()
  }

  // 获取用户列表数据
  getUserList () {
    this.pager.request({
      callback: apiWithdrawLists,
      params: {
        ...this.form
      }
    })
  }

  // 重置按钮
  onReset () {
    this.form = {
      title: ''
    }
    this.getUserList()
  }

  // 用户详情
  DetailsClick (item: any) {
    this.$router.push({
      path: '/gallery/designer/desginer_details',
      query: {
        id: item.id
      }
    })
  }

  // 冻结用户
  handleFrozen (userInfo: any, val: any) {
    console.log(userInfo, val)
    const { id } = userInfo
    this.setUserInfo(id, val)
  }

  // 设置用户信息
  setUserInfo (id: number | string, value: any) {
    if (value == 1) {
      apiWithdrawPass({
        id: id
      }).then(res => {
        this.getUserList()
      })
    } else {
      apiWithdrawRefuse({
        id: id
      }).then(res => {
        this.getUserList()
      })
    }
  }

  // 批量冻结
  handleBatchFrozen (value: any) {
    const userIds = this.multipleSelection.map((item: any) => item.id)
    this.setUserInfo(userIds.join(','), value)
  }
  /** E Methods **/

  /** S Life Cycle **/
  created () {
    this.getUserList()
  }
  /** E Life Cycle **/
}
